.header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.header-container img {
  max-height: 15vh;
  padding-bottom: 1rem;
  padding-top: 1rem;
  object-fit: scale-down;
}

.logo-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
}

.App-logo {
  padding-left: 1rem;
}

.phone-numbers {
  display: flex;
  flex-direction: column;
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  background-color: var(--primary) !important;
}

.link {
  display: table-row;
}

.link b {
  display: table-cell;
}

.link p {
  display: table-cell;
}
.contact-info {
  list-style: none;
  display: table;
  font-family: garamond;
}

.phone-numbers {
  list-style: none;
  display: table;
}

@media (max-width: 900px) {
  .header-container img {
    /* max-height: 10vh; */
  }

  /* .link {
    display: flex;
    align-self: center;
  }

  .link b {
    display: block;
  }

  .contact-info {
    display: flex;
    font-family: garamond;
  } */

  .phone-numbers {
    display: flex;
    flex-direction: row;
  }

  .logo-contact {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
  }

  .logo-contact {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
  }

  .App-logo {
    padding-left: 0rem;
    width: 100vw;
  }

  .left-phone {
    border-right: 1px solid black;
  }

  .contact-info {
    justify-content: center;
    align-items: center !important;
  }

  .contact-container {
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
    padding-bottom: 0.5rem;
  }

  .header-container {
    justify-content: center;
    align-items: center;
  }
}
