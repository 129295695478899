.hero-image img {
  max-height: 30vh;
  object-fit: scale-down;
}

.hero-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
}

.hero-container .hero-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
}

.hero-text p {
  width: 80%;
  text-align: start;
}

@media (max-width: 930px) {
  .hero-container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .hero-container .hero-text {
    width: 100%;
  }
}
