.footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--primary);
  padding: 1rem;
  min-height: 3rem;
}

.navLink {
  font: 400 1rem "Roboto", sans-serif;
  color: #000000;
  text-decoration: none;
  font-size: 0.9rem;
  padding: 0.9rem;
}

.navLink:hover {
  background-color: #000000;
  border-radius: 5px;
  color: #ffffff;
}

.nav-page-set {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
}

.contact-data {
  display: table;
  list-style: none;
}

.unit {
  font: 400 0.9rem "Roboto", sans-serif;
  display: table-row;
}

a {
  color: #000000;
  text-decoration: none;
  display: table-cell;
  text-align: left;
  padding-block: 0.1rem; /* Add padding to create spacing between rows */
}

b {
  display: table-cell;
  padding-right: 1em;
}

hr {
  display: none;
}

@media (max-width: 950px) {
  .footer-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .contact-data {
    padding-bottom: 1rem;
  }

  hr {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    border-top: 1px solid #000000;
  }
}
