.navbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: calc(100vw - 2rem);
  background-color: #ecc188;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.menu-button {
  border-radius: 25% !important;
  height: 50px !important;
  width: 20px !important;
  display: none !important;
}

.nav-link {
  color: #000000;
  margin: 0 1rem;
  text-decoration: none;
  font-size: 1.5rem;
  font-family: "garamond";
  padding-inline: 1rem;
}

.nav-link:hover {
  background-color: #000000;
  border-radius: 5px;
  color: #ffffff;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Navbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100vw);
  background-color: #ecc188;
}
.link {
  font-family: "garamond";
}

.contact-link {
  display: none;
}

.drawer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.drawer-links .nav-link {
  padding-block: 1rem;
}

@media (max-width: 900px) {
  .Navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .menu-button {
    display: flex !important;
  }

  .navbar-container {
    display: none;
  }

  .contact-link {
    display: block;
    text-decoration: none;
    color: #000000;
    font-size: 0.8rem;
  }
  .contact-info {
    padding-top: 1rem;
  }
}
